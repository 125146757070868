body {
    background-color: #000;
}

.header {
    position: absolute;
    width: 100%;
    z-index: 10; /* Asegurarse de que esté encima de la CTA */
}

.navbar {
    background-color: transparent;
    transition: background-color 0.5s ease;
}

.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente al hacer scroll */
}

.navbar-brand {
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
}

.nav-link {
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
    margin-left: 15px;
}

.nav-link:hover {
    color: #4FA6C7; /* Celeste para el hover de los enlaces */
}

/* Estilo específico para el enlace "Home" */
.nav-link.home-link {
    color: #4FA6C7;
    font-weight: bold;
}

/* Estilo específico para el icono del carrito */
.nav-link.cart-icon {
    color: #4FA6C7;
}

@media (max-width: 767.98px) {
    .navbar-brand {
        font-size: 1.3rem;
    }
}
