.testimonials {
    background-color: #f8f9fa;
    padding: 60px 0;
    text-align: center;
}

.testimonials h2 {
    font-size: 2.5rem;
    color: #4FA6C7;
    margin-bottom: 40px;
}

.testimonial-item .card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    max-width: 600px;
    margin: 0 auto;
    height: 250px; /* Altura fija para la tarjeta */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Asegura que el contenido esté centrado verticalmente */
}

.testimonial-item .card-text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
    font-style: italic;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-item .card-title {
    font-size: 1rem;
    color: #333;
    text-align: right;
    margin-top: 20px;
}
