/* FAQ.css */
.faq {
    background-color: #000;
    background-image: url('/src/assets/images/pattern.jpg');
    background-size: cover;
    padding: 60px 0;
    text-align: center;
}

.faq h2 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 0px;
}

.accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #4FA6C7;
    max-width: 800px;
    margin: 0 auto 20px;
}

.accordion-button {
    background-color: #000;
    color: #4FA6C7;
    border: none;
    border-radius: 0;
    padding: 20px;
    font-size: 1.25rem;
    text-align: left;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #000;
    color: #fff;
}

.accordion-button::after {
    filter: invert(1); /* Invertir el color del icono de la flecha */
}

.accordion-body {
    background-color: #000;
    color: #fff;
    padding: 20px;
    font-size: 1rem;
    line-height: 1.5;
}

@media (max-width: 767.98px) {
    .accordion-item {
        max-width: 100%;
    }

    .accordion-button {
        font-size: 1.1rem;
    }
}
