.cta-section {
    position: relative;
    height: 550px; /* Aumenta un poco la altura para más espacio */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cta-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.cta-overlay {
    position: relative;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4); /* Fondo oscuro para mejorar legibilidad */
    padding: 60px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cta-logo {
    width: 300px;
    height: auto;
    margin-right: 0; /* Alinear a la derecha */
    margin-bottom: 0px; /* Espacio debajo del logo */
}

.cta-title {
    margin-bottom: 20px;
    color: #fff;
    font-family: 'League Spartan', sans-serif;
    white-space: nowrap; /* Evitar saltos de línea */
}

.cta-text {
    font-family: 'Nunito Sans', sans-serif;
}

.cta-button {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #4FA6C7;
    border-color: #4FA6C7;
    border-radius: 25px;
    font-weight: 600;
    margin-bottom: 40px; /* Más espacio entre el botón y la parte inferior */
}

.cta-button:hover {
    background-color: #3a8c9f;
    border-color: #3a8c9f;
}

/* Estilos para responsividad */
@media (max-width: 767.98px) {
    .cta-logo {
        width: 150px; /* Ajuste del tamaño en dispositivos pequeños */
        margin-bottom: 20px;
        text-align: center;
    }
    .cta-title {
        white-space: normal; /* Permitir saltos de línea en pantallas pequeñas */
    }
}
