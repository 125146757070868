/* AboutMe.css */
#about-me {
    background-color: #4FA6C7;
    padding: 0px 0;
}

.carousel-item img {
    height: 400px; /* Ajusta la altura según tus necesidades */
    object-fit: cover; /* Mantiene la proporción de la imagen */
    border-radius: 10px;
}
