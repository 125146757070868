.footer {
    padding: 20px 0;
    text-align: center;
    margin-top: 0px;
}

.bg-dark {
    background-color: blueviolet;
}

.footer p {
    font-size: 1rem;
    margin: 0;
}
