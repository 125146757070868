.services {
    background-color: #a4dde6;
    padding: 100px 0 60px; 
    text-align: center;
}

.services h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
}

.service-item {
    padding: 0 15px;
}

.service-item .card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 450px;
}

.service-item .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service-item .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.service-item .card-text {
    font-size: 1rem;
    margin-bottom: 20px;
    flex-grow: 1;
}

.service-item .card-duration {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #555;
    align-items: center;
    justify-content: center;
}

.service-item .service-icon {
    max-height: 70px; 
    object-fit: contain;
    margin-bottom: 15px;
    padding-top: 10px; 
}

.cta-button {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #4FA6C7;
    border-color: #4FA6C7;
    border-radius: 25px;
    font-weight: 600;
    margin-bottom: 40px;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
}

.cta-button:hover {
    background-color: #3a8c9f;
    border-color: #3a8c9f;
}

.service-item .card.upcoming {
    position: relative;
    overflow: hidden;
}

.service-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 15px;
}
